<template>
  <v-container class="full-width-container">
    <Settings v-if="loggedIn" />
    <Auth v-if="!loggedIn" />
  </v-container>
</template>

<script>
  import Settings from '../components/Settings.vue'
  import Auth from '../components/Auth.vue'

  export default {
    name: 'SettingsView',
    components: {
      Settings,
      Auth
    },
    computed: {
      loggedIn: function() {
        return this.$store.getters.loggedIn;
      },
    }
  }
</script>
