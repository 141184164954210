<template>
    <v-container class="partial-width-container">
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <span class="text-h5 text-xl-h5 text-lg-h5 text-md-h5">
                            {{ partyName }} Settings
                        </span>
                        <v-spacer></v-spacer>
                        <v-btn 
                            v-on:click="dialog=true"
                            color="red"
                            :large="$vuetify.breakpoint.lgAndUp" 
                            :small="$vuetify.breakpoint.smAndDown"
                            outlined
                            icon
                        >
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-btn
                            v-on:click="downloadPartyDetails"
                            color="primary"
                            depressed
                        >
                            <v-icon left>
                                mdi-download
                            </v-icon>
                            Download Party Details
                        </v-btn>
                    </v-card-text>
                    <v-row>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                            <v-list two-line>
                                <v-subheader>
                                    PARTY MEMBERS
                                </v-subheader>
                                <v-list-item-group>
                                    <template v-for="(member, index) in partyMembersEdit">
                                        <v-list-item :key="member.member">
                                            <v-list-item-content>
                                                <v-list-item-title 
                                                    v-text="member.member" 
                                                    v-if="!member.editing"
                                                ></v-list-item-title>
                                                <v-text-field
                                                    v-if="member.editing" 
                                                    v-model="member.edited" 
                                                    v-on:keyup.enter="editPartyMember(member)"
                                                    class="mt-4 mb-0 mr-8"
                                                    dense
                                                ></v-text-field>
                                            </v-list-item-content>
                                            <v-list-item-icon v-if="member.editing" class="mt-7 mb-2"> 
                                                <v-btn
                                                    v-on:click="editPartyMember(member)"
                                                    color="green"
                                                    :small="$vuetify.breakpoint.xs"
                                                    aria-label="save party member"
                                                    outlined
                                                    icon
                                                >
                                                    <v-icon>
                                                        mdi-check
                                                    </v-icon>
                                                </v-btn>
                                            </v-list-item-icon>
                                            <v-list-item-icon v-if="member.editing" class="mt-7 mb-2"> 
                                                <v-btn
                                                    v-on:click="member.editing = !member.editing"
                                                    color="red"
                                                    :small="$vuetify.breakpoint.xs"
                                                    aria-label="close edit party member"
                                                    outlined
                                                    icon
                                                >
                                                    <v-icon>
                                                        mdi-close
                                                    </v-icon>
                                                </v-btn>
                                            </v-list-item-icon>
                                            <v-list-item-icon v-if="!member.editing" class="mt-7 mb-5"> 
                                                <v-btn
                                                    v-on:click="member.editing = !member.editing"
                                                    color="primary"
                                                    :small="$vuetify.breakpoint.xs"
                                                    aria-label="edit party member"
                                                    outlined
                                                    icon
                                                >
                                                    <v-icon>
                                                        mdi-pencil
                                                    </v-icon>
                                                </v-btn>
                                            </v-list-item-icon>
                                            <v-list-item-icon v-if="!member.editing" class="mt-7 mb-5">
                                                <v-btn 
                                                    v-on:click="deletePartyMember(member)"
                                                    color="red"
                                                    :small="$vuetify.breakpoint.xs"
                                                    aria-label="delete party member"
                                                    outlined
                                                    icon
                                                >
                                                    <v-icon>
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                            </v-list-item-icon>
                                        </v-list-item>
                                        <v-divider 
                                            :key="index"
                                            class="ml-4"
                                        ></v-divider>
                                    </template>
                                    <v-list-item v-if="memberAdd">
                                        <v-list-item-content>
                                            <v-text-field 
                                                v-on:keyup.enter="addPartyMember"
                                                v-model="newMember" 
                                                label="New Member" 
                                                class="mt-4 mb-0 mr-8"
                                                dense
                                            >
                                            </v-text-field>
                                        </v-list-item-content>
                                        <v-list-item-icon> 
                                            <v-btn
                                                v-on:click="addPartyMember"
                                                color="green"
                                                :small="$vuetify.breakpoint.xs"
                                                aria-label="add party member"
                                                outlined
                                                icon
                                            >
                                                <v-icon>
                                                    mdi-check
                                                </v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-icon> 
                                            <v-btn
                                                v-on:click="memberAdd = !memberAdd"
                                                color="red"
                                                :small="$vuetify.breakpoint.xs"
                                                outlined
                                                icon
                                            >
                                                <v-icon>
                                                    mdi-close
                                                </v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                    </v-list-item>
                                </v-list-item-group>
                                <v-card-actions>
                                    <v-btn
                                        v-on:click="memberAdd = !memberAdd"
                                        class="mt-4 ml-1"
                                        color="green"
                                        outlined
                                        icon
                                    >
                                        <v-icon>
                                            mdi-plus
                                        </v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-list>
                        </v-col>
                        <v-divider 
                            v-if="$vuetify.breakpoint.smAndUp"
                            class="mb-8"
                            vertical
                        ></v-divider>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                            <validation-observer 
                                v-slot="{ invalid }"
                                ref="observer"
                            >
                                <v-form>
                                    <v-list two-line>
                                        <v-subheader>
                                            PARTY DETAILS
                                        </v-subheader>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <validation-provider 
                                                    v-slot="{ errors }"
                                                    name="Party name"
                                                    rules="max:100" 
                                                >
                                                    <v-text-field 
                                                        v-model="partyNameNew" 
                                                        label="Party Name" 
                                                        class="mt-4 mb-0 mr-8"
                                                        :error-messages="errors"
                                                        dense
                                                        required
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mr-4"></v-divider>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <validation-provider 
                                                    v-slot="{ errors }"
                                                    name="Party secret"
                                                    rules="required|min:6|max:100" 
                                                >
                                                    <v-text-field 
                                                        v-model="partySecret" 
                                                        label="Party Secret (Required)" 
                                                        type="password"
                                                        class="mt-4 mb-0 mr-8"
                                                        :error-messages="errors"
                                                        dense
                                                        required
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mr-4"></v-divider>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <validation-provider 
                                                    v-slot="{ errors }"
                                                    name="New party secret"
                                                    rules="min:6|max:100" 
                                                >
                                                    <v-text-field 
                                                        v-model="partySecretNew" 
                                                        label="New Party Secret" 
                                                        type="password"
                                                        class="mt-4 mb-0 mr-8"
                                                        :error-messages="errors"
                                                        dense
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mr-4"></v-divider>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <validation-provider 
                                                    v-slot="{ errors }"
                                                    name="Confirm party secret"
                                                    rules="min:6|max:100" 
                                                >
                                                    <v-text-field 
                                                        v-model="partySecretConfirm" 
                                                        label="Confirm Party Secret" 
                                                        type="password"
                                                        class="mt-4 mb-0 mr-8"
                                                        :error-messages="errors"
                                                        dense
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mr-4"></v-divider>
                                        <v-list-item>
                                            <v-list-item-content class="mr-8">
                                                <v-btn 
                                                    class="mt-4 mb-4" 
                                                    color="primary" 
                                                    v-on:click="updatePartyDetails"
                                                    :disabled="invalid"
                                                    depressed
                                                >
                                                    Update
                                                </v-btn>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-form>
                            </validation-observer>
                        </v-col>
                    </v-row>
                </v-card>  
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbarSuccess"
            timeout="5000"
            color="green"
            dark
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on:click="snackbar = false"
                    text
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar
            v-model="snackbarError"
            timeout="5000"
            color="red"
            dark
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on:click="snackbar = false"
                    text
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-dialog
            v-model="dialog"
            width="700"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">
                        Are you sure you want to delete your account? 
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-on:click="dialog = false"
                        icon
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="mt-4 text-body-1">
                    This action cannot be reversed. 
                    Before deleting, you are encouraged to back up your party details via the "Download Party Details" button on this page.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        v-on:click="deleteAccount"
                        color="red"
                        text
                    >
                        Delete
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        name: 'Settings',
        data() {
            return {
                memberAdd: false,
                newMember: '',
                partyMembersEdit: [],
                partyNameNew: '',
                partySecret: '',
                partySecretNew: '',
                partySecretConfirm: '',
                invalid: true,
                snackbarSuccess: false,
                snackbarError: false,
                snackbarMessage: '',
                dialog: false,
            };
        },
        computed: {
            // party name
            partyName: function() {
                return this.$store.getters.partyName;
            },

            // party members
            partyMembers: function() {
                return this.$store.getters.partyMembers;
            },

            // party loot
            partyLoot: function() {
                return this.$store.getters.partyLoot;
            },

            // party coins
            partyCoins: function() {
                return this.$store.getters.partyCoins;
            }
        },
        watch: {
            // reset party members for editing
            partyMembers : function() {
                this.partyMembersEdit = [];
                this.partyMembers.forEach((member) => {
                    this.partyMembersEdit.push({
                        'member' : member,
                        'edited' : member,
                        'editing' : false,
                    });
                })
            }
        },
        methods: {
            // download JSON file of party details
            downloadPartyDetails() {
                // create a JSON object for party details
                const partyDetails = {
                    'partyName' : this.partyName,
                    'partyMembers' : this.partyMembers,
                    'partyCoins' : this.partyCoins,
                    'partyLoot' : []
                };

                // add all party loot to the JSON object
                this.partyLoot.forEach((item) => {
                    partyDetails.partyLoot.push(item);
                });

                // download the JSON object as blob
                const download = JSON.stringify(partyDetails, null, 2);
                const a = document.createElement("a");
                const file = new Blob([download], {type: 'text/plan'});
                a.href = URL.createObjectURL(file);
                a.download = 'partyDetails.txt';
                a.click();
            },

            // add a new party member
            addPartyMember() {
                // check that the member isn't already in the party 
                // and that the member's name has at least one character
                if (!this.partyMembers.includes(this.newMember) && this.newMember.length > 0) {
                    // update the member list
                    this.partyMembersEdit.push({
                        'member' : this.newMember,
                        'edited' : this.newMember,
                        'editing' : false,
                    })

                    // add the new member to the list of party members
                    const partyMembersNew = [this.newMember].concat(this.partyMembers);

                    // create an object for the party members
                    const partyMembersDetails = {
                        'partyMembers' : partyMembersNew,
                        'newMember' : this.newMember,
                        'operation' : 'add'
                    }

                    // push the new party members list to state
                    // display a success or failure message on completion
                    this.$store.dispatch('editPartyMembers', partyMembersDetails)
                    .then(() => { 
                        this.snackbarSuccess = true;
                        this.snackbarMessage = 'Party Members Updated Successfully!';
                    }, () => {
                        this.snackbarError = true;
                        this.snackbarMessage = 'Invalid Party Member Details; Update Failed';
                    });
                }

                // reset
                this.newMember = '';
                this.memberAdd = false;
            },

            // edit a party member
            editPartyMember(editMember) {
                // check that the member isn't already in the party and has at least one character
                if (!this.partyMembers.includes(editMember.edited) && editMember.edited.length > 0) {
                    // update the member list
                    this.partyMembersEdit[this.partyMembersEdit.indexOf(editMember)] = {
                        'member' : editMember.edited,
                        'edited' : editMember.edited,
                        'editing' : false,
                    }

                    // update the list of party members
                    let partyMembersNew = [];
                    this.partyMembers.forEach((member) => { 
                        if (member != editMember.member) { 
                            partyMembersNew.push(member) 
                        } else { 
                            partyMembersNew.push(editMember.edited) 
                        }
                    }); 

                    // create an object for the party members
                    const partyMembersDetails = {
                        'partyMembers' : partyMembersNew,
                        'newMembers' : editMember.edited,
                        'operation' : 'edit'
                    }

                    // push the new party members list to state
                    // display a success or failure message on completion
                    this.$store.dispatch('editPartyMembers', partyMembersDetails)
                    .then(() => { 
                        // query each item to change the owner, if relevant
                        this.partyLoot.forEach((item) => {
                            const newItem = item;
                            newItem.individuals.forEach((i) => {
                                if (i.owner == editMember.member) {
                                    i.owner = editMember.edited;
                                }
                            });
                            newItem.createdAt = new Date(newItem.createdAt).getTime();
                            newItem.lastModifiedAt = new Date(newItem.lastModifiedAt).getTime();
                            this.$store.dispatch('editLoot', newItem)
                        });

                        // display a success message
                        this.snackbarSuccess = true;
                        this.snackbarMessage = 'Party Members Updated Successfully!';
                    }, () => {
                        this.snackbarError = true;
                        this.snackbarMessage = 'Invalid Party Member Details; Update Failed';
                    });
                }

                // reset
                editMember.editing = false;
            },

            // delete a party member
            deletePartyMember(deleteMember) {
                // update the member list quickly
                this.partyMembersEdit.splice(this.partyMembersEdit.indexOf(deleteMember), 1);

                // update the list of party members
                const partyMembersNew = this.partyMembers
                partyMembersNew.splice(partyMembersNew.indexOf(deleteMember.member), 1);

                // query each item to change the owner, if relevant
                this.partyLoot.forEach((item) => {
                    const newItem = item;
                    newItem.individuals.forEach((i, index) => {
                        if (i.owner === deleteMember.member) {
                            if (newItem.individuals.some(ii => ii.owner == 'Party')) {
                                newItem.individuals.forEach((ii) => {
                                    if (ii.owner == 'Party') {
                                        ii.quantity += i.quantity;
                                    }
                                });
                            } else {
                                newItem.individuals.push({ 'owner' : 'Party', 'quantity' : i.quantity })
                            }
                            newItem.individuals.splice(index, 1)
                        }
                    });
                    newItem.createdAt = new Date(newItem.createdAt).getTime();
                    newItem.lastModifiedAt = new Date(newItem.lastModifiedAt).getTime();
                    this.$store.dispatch('editLoot', item)
                    .then(() => { }, () => {
                        this.snackbarError = true;
                        this.snackbarMessage = 'Invalid Party Member Details; Update Failed';
                    });
                });

                // create an object for the party members
                const partyMembersDetails = {
                    'partyMembers' : partyMembersNew,
                    'newMember' : '',
                    'operation' : 'delete'
                };

                // push the new party members list to state
                // display a success or failure message on completion
                this.$store.dispatch('editPartyMembers', partyMembersDetails)
                .then(() => { 
                    this.snackbarSuccess = true;
                    this.snackbarMessage = 'Party Members Updated Successfully!';
                }, () => {
                    this.snackbarError = true;
                    this.snackbarMessage = 'Invalid Party Member Details; Update Failed';
                });
            },

            // update party details
            updatePartyDetails() {
                // create an email from the party name
                const email = this.partyName.toLowerCase().replace(/\s/g,'') + '@partyloottracker.com'
                const emailNew = this.newPartyName.toLowerCase().replace(/\s/g,'') + '@partyloottracker.com'

                // create an object with the party details
                const partyDetails = {
                    'partyEmail': email,
                    'partySecret': this.partySecret,
                    'partyName': this.partyNameNew,
                    'partyEmailNew': emailNew,
                    'partySecretNew': this.partySecretNew
                }

                // check if there's a password and if it matches
                // if not, display an error message 
                if (this.partySecretNew === this.partySecretConfirm) {
                    // push the new party members list to state
                    // display a success or failure message on completion
                    this.$store.dispatch('updatePartyDetails', partyDetails).then(() => {
                        this.snackbarSuccess = true;
                        this.snackbarMessage = 'Party Details Updated Successfully!'
                    }, (error) => {
                        this.snackbarError = true;
                        this.snackbarMessage = error;
                    });
                } else {
                    this.snackbarError = true;
                    this.snackbarMessage = 'Secrets Do Not Match!'
                }
            },

            // delete account
            deleteAccount() {
                this.$router.push({ name: 'HomeView' });
                this.$store.dispatch('deleteAccount');
            }
        },
        created() {
            // initialize party members for editing
            this.partyMembersEdit = [];
            this.partyMembers.forEach((member) => {
                this.partyMembersEdit.push({
                    'member' : member,
                    'edited' : member,
                    'editing' : false,
                });
            })

            // transfer party name
            this.partyNameNew = this.partyName;
        }
    }
</script>